<template>
  <div class="container_Details">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 pt-5">
        <img
          class="img-fluid grayscale"
          src="@/assets/logo.webp"
          width="300"
          height="100"
          alt="maid_logo"
        />
        <br />
        <p class="comDes pt-3 text-justify">
          Is a consulting company providing services and technology solutions.
          We stand beside all businesses to help them shape and drive their
          transformation journey. We are always at the forefront of innovation.
        </p>
        <div class="pt-4">
          <a
            type="button"
            class="btn btn-outline-light"
            href="/report-vulnerability"
          >
            <b> REPORT VULNERABILITY</b>
          </a>
        </div>
        <div class="pt-4">
          <a type="button" class="btn btn-outline-light" href="/privacy-policy">
            <b> PRIVACY POLICY</b>
          </a>
        </div>
        <div class="pt-4">
          <a type="button" class="btn btn-outline-light" href="#">
            <b>TERMS OF USE</b>
          </a>
        </div>
        <div class="pt-4">
          <a
            type="button"
            class="btn btn-outline-light"
            href="https://maid-agency-software-singapore.blogspot.com/2022/09/what-digital-automation-really-mean-for.html"
            target="_blank"
          >
            <b>BLOG</b>
          </a>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 pt-5">
        <h5 class="Link"><b>CONTACT US</b></h5>
        <p class="pt-3">
          <i
            class="fa fa-mobile fa-1x"
            style="color: white; font-size: 36px"
            aria-hidden="true"
            >&nbsp;&nbsp;</i
          ><span
            ><a
              href="https://api.whatsapp.com/send?phone=6597223997&text=I%27m%20interested%20in%20your%20Maid%20Agency%20Software"
              target="_blank"
              >+65-97223997</a
            ></span
          >
        </p>
        <hr />

        <p class="pt-3">
          <i
            class="fa fa-envelope fa-1x"
            style="color: white; font-size: 36px"
            aria-hidden="true"
          ></i
          >&nbsp;&nbsp;<span><a href="">info@MaidAgencySoftware.com</a></span>
        </p>

        <hr class="my-4 custom-hr" />

        <img
          class="img-fluid grayscale"
          src="@/assets/csr.webp"
          width="150"
          height="100"
          alt="logo"
          style="filter: brightness(0) invert(1)"
        />

        <p class="pt-3">
          <a href="#" span class="text-white" target="_blank"
            >Corporate Social Responsibility</a
          >
        </p>
      </div>

      <div class="col-sm-12 col-md-4 col-lg-4 pt-5">
        <h5 class="Link"><b>OTHER SERVICES</b></h5>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;</i
          ><a
            href="https://kenwynaccounting.com/accounting-services"
            span
            class="text-secondary"
            target="_blank"
            >Company Registration</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://kenwynaccounting.com/accounting-services"
            span
            class="text-secondary"
            target="_blank"
            >Keep Booking</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://kenwynaccounting.com/accounting-services"
            span
            class="text-secondary"
            target="_blank"
            >Payroll Management</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://kenwynaccounting.com/accounting-services"
            span
            class="text-secondary"
            target="_blank"
            >24/7 Support Desk</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://kenwynaccounting.com/accounting-services"
            span
            class="text-secondary"
            target="_blank"
            >Digital Marketing</a
          >
        </p>

        <h5 class="Link pt-3"><b>OTHER SERVICES</b></h5>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;</i
          ><a
            href="/become-a-partner"
            span
            class="text-secondary"
            target="_blank"
            >Become a Partner</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a href="#" span class="text-secondary" target="_blank">Events</a>
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://maid-agency-software-singapore.blogspot.com/2022/09/what-digital-automation-really-mean-for.html"
            span
            class="text-secondary"
            target="_blank"
            >Blog</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a href="#" span class="text-secondary" target="_blank">FAQ</a>
        </p>
      </div>
    </div>
    <hr class="my-4 custom-hr" />
    <div class="copyRightCls text-center"></div>
  </div>
</template>
<style scoped>
.container_Details {
  background: rgb(43, 42, 42);
  padding: 30px;
}
.Link {
  color: white;
}
a {
  text-decoration: none;
  color: aliceblue;
}
.copyRightCls {
  color: rgb(165, 160, 160);
}
hr {
  background: #a5a0a0;
  opacity: 0.2;
}
.comDes {
  color: white;
}

.custom-hr {
  border-color: #a5a0a0; /* Red color */
  border-width: 1px; /* Thickness of the line */
}

.color-overlay {
  position: relative;
  display: inline-block;
}

.color-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(141, 141, 141, 0.5); /* Green with 50% opacity */
  pointer-events: none; /* Allow clicks to go through the overlay */
}

.grayscale {
  filter: grayscale(100%);
}

@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
  .text-head {
    font-size: 70px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 100%;
  }
  .text-head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
  .text-head {
    font-size: 30px;
  }
}
</style>
